import React from "react";
import {
  analytics_gif,
  build_community_gif,
  develope_assist_gif,
  fundraise_gif,
  launch_pro_gif,
  mint_smart_gif,
} from "../asset";

interface SolutionCardProps {
  title: string;
  description?: string;
  imageSrc: string;
  link?: string;
}

const Solution: React.FC<SolutionCardProps> = ({
  title,
  description,
  imageSrc,
  link,
}) => {
  return (
    <div className="w-full bg-[#141416] rounded-lg shadow-lg p-5 text-white flex flex-col items-center">
      <img
        src={imageSrc}
        alt={title}
        className="w-full rounded-xl mb-4 border border-gray-600"
      />
      <div className="w-full flex items-center justify-between bg-[#141416]">
        <h3 className="text-[#fbfbfb] text-xl font-bold bg-[#141416]">
          {title}
        </h3>

        {/* {description && (
          <p className="text-gray-400 text-sm mb-4">{description}</p>
        )} */}
        <a href={link} className="bg-[#141416]">
          <span className="text-[#fbfbfb] font-semibold mb-5 bg-[#141416]">
            →
          </span>
        </a>
      </div>
    </div>
  );
};

const SolutionCard: React.FC = () => {
  const solutions = [
    {
      title: "Develop Assets",
      imageSrc: develope_assist_gif,
      link: "#",
    },
    {
      title: "Mint Smart Contract",
      imageSrc: mint_smart_gif,
      link: "#",
    },
    {
      title: "Build Community",
      imageSrc: build_community_gif,
      link: "#",
    },
    {
      title: "Fundraise",
      imageSrc: fundraise_gif,
      link: "#",
    },
    {
      title: "Launch Pro Marketplace",
      imageSrc: launch_pro_gif,
      link: "#",
    },
    {
      title: "Analytics Beta",
      imageSrc: analytics_gif,
      link: "#",
    },
  ];

  return (
    <div className="min-h-screen bg-black flex flex-col items-center md:p-3">
      <p className="text-md font-bold text-[#A49BFF] mt-10 mb-4">
        POLYCRUZ PLUG & PLAY INFRASTRUCTURE
      </p>
      <h1 className="text-xl font-bold text-white mb-8">SOLUTIONS</h1>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 px-4 max-w-7xl">
        {solutions.map((solution, index) => (
          <Solution
            key={index}
            title={solution.title}
            imageSrc={solution.imageSrc}
            link={solution.link}
          />
        ))}
      </div>
    </div>
  );
};

export default SolutionCard;
